import type { SvgIconComponent } from '@mui/icons-material'
import { Box, Button, Card, CardContent, Typography } from '@mui/material'

import { useIsMobile } from '../../hooks/useIsMobile'

export type OperationType = {
  description: string
  onClick: () => void
  icon: SvgIconComponent
  buttonColor: 'primary' | 'secondary'
}

type Props = {
  title: string
  description?: string
  operationType: OperationType[]
}

export function NewOperationsCard({
  title,
  description,
  operationType,
}: Readonly<Props>): JSX.Element {
  const isMobile = useIsMobile()

  return (
    <Card
      sx={{
        maxWidth: '342px',
        width: '100%',
        maxHeight: '220px',
        borderRadius: '8px',
        p: 2,
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CardContent
        sx={{
          p: 0,
          '&:last-child': {
            pb: 0,
          },
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{ mb: '7px' }}
          data-intercom-target="PurchaseProduct"
        >
          {title}
        </Typography>

        {description ? (
          <Typography variant="caption" component="p" sx={{ mb: 2 }}>
            {description}
          </Typography>
        ) : null}

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          {operationType.map((type) => (
            <Button
              key={type.description}
              variant="contained"
              startIcon={<type.icon />}
              onClick={type.onClick}
              color={type.buttonColor}
              sx={{ mb: 1, width: '310px', height: '38px' }}
            >
              {type.description}
            </Button>
          ))}
        </Box>
      </CardContent>
    </Card>
  )
}
